.servicesTwo {
    padding: 100px 50px 50px;

    .servicesTwoTitle{
        display: grid;
        justify-content: center;
    
        h2 {
            color: var(--cor-primary);
            font-size: 30px !important;
            font-weight: 700;
            text-align: center;
            z-index: 2;
        }
    
        h2.visible {
            animation: slideToRight 2s ease forwards;
        }

        img{
            position: relative;
            top: -15px;
            z-index: 1;
            opacity: 50%;
        }
    }

    .service {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        padding: 2rem 3rem;

        .servicesTwoText {
            h1 {
                color: var(--cor-tertiary);
                font-size: 30px !important;
                font-weight: 700;
                opacity: 0%;
            }
    
            h1.visible {
                animation: slideToRight 2s ease forwards;
            }
    
            span {
                color: var(--cor-secondary);
            }
    
            p {
                padding-top: 20px;
                width: 750px;
                line-height: 25px;
                font-size: 16px;
                opacity: 0%;
            }
    
            p.visible {
                animation: slideToRight 2s .2s ease forwards;
            }
        }
    
        img {
            transition: .5s ease;
            width: 400px;
            cursor: pointer;
            border-radius: 10px;
        }
    
        img:hover {
            scale: 1.08;
        }
    
        img.visible {
            animation: slideToLeft 2s .4s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .servicesTwo {

        .servicesTwoText {
            p {
                width: 650px;
                font-size: 16px !important;
            }

            img {
                margin: 0px;
                width: 300px;
            }
        }

    }
}

@media screen and (max-width: 1400px) {
    .servicesTwo {
        .service{
            padding: 20px 0px;
            .servicesTwoText {
                h1{
                    font-size: 25px !important;
                }
                p {
                    width: 100% !important; 
                    max-width: 650px;
                    padding: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .servicesTwo {
        .servicesTwoText {
            img {
                width: 100px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .servicesTwo {
        .service{
            display: grid;
            gap: 20px;
        }
    }
}

@media screen and (max-width: 500px) {
    .servicesTwo {
        padding: 80px 20px 30px;
        .service{
            .servicesTwoText{
                h1{
                    font-size: 22px !important;
                }
                p{
                    font-size: 14px !important;
                }
            }
            img{
                width: 100%;
            }
        }
    }
}