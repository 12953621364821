@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
  margin:0;
}

.header {
    padding: 0px;
    grid-area: hero;
    position:relative;
    text-align:center;
    background: linear-gradient(60deg, rgba(83, 58, 183, 0) 0%, rgba(0, 171, 193, 0) 100%);
    color:white;
    z-index: 1000;
    position: relative;
    margin-top: auto;
    margin-bottom: 0px;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:30vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:200px;
  max-height:300px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: rgb(150, 0, 0);
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:200px;
    min-height:200px;
  }
  .content {
    height:50vh;
  }
}