.vesselsFour {
    padding: 50px 0px 50px;

    .vesselsFourTitle {
        text-align: center;
        display: grid;
        justify-content: center;
        padding-bottom: 50px;

        h1 {
            opacity: 0%;
            color: var(--cor-secondary);
            font-weight: 700;
            max-width: 550px;
            justify-self: center;
            margin-bottom: 30px;

            span {
                color: var(--cor-primary);
            }
        }

        h1.visible {
            animation: halfToUp 1s ease forwards;
        }

        h2 {
            opacity: 0%;
            color: var(--cor-tertiary);
        }

        h2.visible {
            animation: halfToUp 1s .2s ease forwards;
        }

        p {
            opacity: 0%;
            max-width: 750px;
            margin: 0px;
            font-size: 16px;
        }

        p.visible {
            animation: halfToUp 1s .4s ease forwards;
        }
    }

    .swiper {
        opacity: 0%;
        max-width: 1200px;
        border-radius: 10px;
        cursor: pointer;

        .swiper-button-prev:after,
        .swiper-button-next:after {
            display: none;
        }

        .swiper-slide {
            transition: .5 ease;
            height: 600px;

            .card {
                padding: 0px !important;
                border: none;

                img {
                    object-fit: cover;
                    transition: .5s ease;
                }

                img:hover {
                    scale: 1.1;
                    z-index: 1000;
                }
            }

            .card1,
            .card2 {
                img {
                    display: block;
                    width: 100%;
                    height: 250px;
                }
            }

            .card3 {
                img {
                    height: 250px;
                }
            }
        }

        .swiper-slide:hover {
            z-index: 1000;
        }
    }

    .swiper.visible {
        animation: halfToUp 1s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .vesselsFour {
        .vesselsFourTitle {
            h1 {
                margin-bottom: 20px
            }

            ;
        }

        .swiper {
            max-width: 1000px;

            .swiper-slide {
                height: 500px;

                .card1,
                .card2 {
                    img {
                        height: 250px;
                    }
                }

                .card3 {
                    img {
                        height: 250px;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 1200px) {
    .vesselsFour {
        padding: 100px 20px 50px;

        .swiper {
            max-width: 900px;

            .swiper-button-next,
            .swiper-button-prev {
                img {
                    width: 25px;
                }
            }

            .swiper-slide {
                height: 400px;

                .card1,
                .card2 {
                    img {
                        height: 200px;
                    }
                }

                .card3 {
                    img {
                        height: 400px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .vesselsFour {
        padding: 50px 20px 50px;

        .swiper {
            max-width: 600px;
        }
    }
}

@media screen and (max-width: 500px) {
    .vesselsFour {
        padding: 50px 20px 50px;

        .vesselsFourTitle {
            padding-bottom: 20px;
        }

        .swiper {
            max-width: 100%;

            .swiper-slide {
                height: 500px;

                .card1,
                .card2 {
                    img {
                        height: 250px;
                    }
                }

                .card3 {
                    img {
                        height: 500px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .vesselsFour {
        padding: 50px 20px 50px;

        .vesselsFourTitle {
            p {
                font-size: 14px;
            }
        }
    }
}