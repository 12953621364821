.aboutThree {
    padding: 100px 150px 50px;
    padding-top: 0px !important;
    background-image: linear-gradient(45deg, #004f2df1, #003015d6, #002012bf), url('../../img/about/img-6.webp');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    text-align: center;

    .aboutThreeTitle {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 50vh;
        width: 80%;

        h1 {
            color: #fff;
            font-weight: 700;
            font-size: 22px;
            opacity: 0%;
        }

        p {
            color: #fff;
            font-size: 18px;
        }


        h1.visible {
            animation: slideToDown 1s ease forwards;
        }

        p.visible {
            animation: slideToDown 1s ease forwards;
        }
    }


}

.aboutThree.visible {
    animation: changeOpacity 2s ease forwards;
}

@media screen and (max-width: 1600px) {
    .aboutThree {
        padding: 0px 150px 50px;

        .swiper {
            background-size: 1900px;
            padding: 50px 0px;
            box-sizing: border-box;

            .swiper-slide {
                height: 500px;

                .card {
                    h2 {
                        font-size: 25px !important;
                    }

                    h3 {
                        font-size: 20px !important;
                    }

                    p {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .aboutThree {
        .swiper {
            .truck {
                top: 48%;
            }

            .swiper-slide {
                .card {
                    padding: 0px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .aboutThree {
        padding: 100px 50px 50px;

        .swiper {
            background-position: 50% 55%;
            padding: 50px 0px 0px;

            .swiper-slide {
                height: 450px;
            }

            .swiper-button-prev {
                top: 56%;
                left: -4px;
            }

            .swiper-button-next {
                top: 56%;
                right: -4px;
            }

            .truck {
                top: 47.2%;
                width: 120px;
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .aboutThree {
        .swiper {
            .swiper-slide {
                height: 500px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .aboutThree {

        background-size: 150%;

        .swiper {
            background-position: 100% 60%;

            .swiper-button-prev {
                top: 61%;

            }

            .swiper-button-next {
                top: 61%;
            }

            .truck {
                top: 53%;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .aboutThree {
        .swiper {
            background-position: 100% 96%;

            .truck {
                top: 78%;
            }

            .swiper-button-next {
                top: 94%;
            }

            .swiper-button-prev {
                top: 94%;
            }

            .swiper-slide {
                height: auto;
                padding-bottom: 80px;

                .card {
                    align-items: start;
                    top: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .aboutThree {
        .swiper {
            .truck {
                top: 82%;
            }

            .swiper-button-next,
            .swiper-button-prev {
                top: 95%;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .aboutThree {
        padding: 0px 20px 20px;

        .swiper {
            .swiper-slide {
                .card {
                    h2 {
                        font-size: 22px !important;
                        margin: 0px;
                    }

                    h3 {
                        font-size: 16px !important;
                    }

                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}