.homeVideo{
    width: 100%;
    display: grid;
    padding: 0px 0px 50px;
    justify-content: center;

    iframe{
        width: 900px;
        height: 450px;
        border-radius: 15px;
        border: 5px solid var(--cor-primary);
    }
}

@media screen and (max-width: 1000px){
    .homeVideo{
        padding: 0px 50px 50px;
        iframe{
            width: 700px;
            height: 350px;

        }
    }
}

@media screen and (max-width: 768px){
    .homeVideo{
        padding: 0px 0px 50px;
        iframe{
            width: 500px;
            height: 300px;

        }
    }
}

@media screen and (max-width: 600px){
    .homeVideo{
        padding: 0px 0px 50px;
        iframe{
            width: 400px;
            height: 300px;

        }
    }
}

@media screen and (max-width: 420px){
    .homeVideo{
        padding: 0px 0px 50px;
        iframe{
            width: 100%;
            height: 300px;

        }
    }
}