.vesselsTwo {
    opacity: 0%;
    padding: 200px 100px;
    height: fit-content;
    display: grid;
    grid-template-columns: 100%;
    background-color: var(--cor-secondary);
    align-items: center;

    .vesselsTwoTitle {
        h1 {
            opacity: 0%;
            font-weight: 700;
            color: var(--cor-tertiary);
            text-align: center;
        }

        h1.visible {
            animation: halfToRight 1s ease forwards;
        }

        p {
            text-align: center;
            opacity: 0%;
            padding-top: 5px;
            width: 100%;
            font-size: 18px;
            color: white;
        }

        p.visible {
            animation: halfToRight 1s .2s ease forwards;
        }
    }

    .swiper {
        top: 20px;
        opacity: 0%;
        height: fit-content;
        max-width: 100%;
        background-image: linear-gradient(to right, var(--cor-secondary), var(--cor-tertiary));
        border-radius: 10px;

        .swiper-button-prev:after,
        .swiper-button-next:after {
            display: none;
        }
        .swiper-button-next, .swiper-button-prev{
            top: 95%;
        }

        .swiper-slide {
            width: 100% !important;

            .card-media {
                padding: 10px;
                display: grid;
                grid-template-columns: 55% 45%;
                gap: 20px;
                width: 100%;
                transition: .5s ease;
                grid-template-areas: 'swiper';

                img {
                    border-radius: 10px;
                    height: auto;
                    width: 100%;
                }

                .media-text {
                    padding: 10px 50px 10px 20px;
                    transition: 0.6s ease-in-out;
                    align-items: center;
                    align-self: center;
                    display: grid;
                    height: fit-content;
                    min-width: 0px;
                    max-width: 100%;

                    h1 {
                        color: rgb(236, 236, 236);
                        margin: 0px;
                        font-weight: 700;
                    }
                    p {
                        margin-top: 10px;
                        color: rgb(238, 238, 238);
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .swiper.visible {
        animation: halfToUp 1s .2s ease forwards;
    }
}

.vesselsTwo.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px) {
    .vesselsTwo {
        padding: 180px 50px 100px;
        .vesselsTwoTitle {
            p {
                font-size: 16px !important;
            }
        }
        .swiper{
            .swiper-button-next, .swiper-button-prev{
                top: 90%;
            }

            .swiper-slide {
                .card-media {
                    video {
                        width: 280px;
                        height: auto;
                    }

                    .media-text {
                        p {
                            max-height: 350px;
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }
}

/* @media screen and (max-width: 1440px) {
    .vesselsTwo {
        .swiper {
            .swiper-slide {
                .card-media {
                    img {
                        width: 450px;
                        height: 280px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
} */

/* @media screen and (max-width: 1300px) {
    .vesselsTwo {
        grid-template-columns: 100%;

        .swiper {
            .swiper-slide {
                .card-media {
                    img {
                        width: 400px;
                    }

                    .media-text {
                        p {
                            max-height: 300px;
                            font-size: 14px !important;
                            -webkit-line-clamp: 16;
                        }
                    }
                }
            }
        }
    }
} */

@media screen and (max-width: 900px) {
    .vesselsTwo {
        .swiper {
            .swiper-slide {
                .card-media {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 100% !important;
                    text-align: right;

                    img {
                        width: 100%;
                        max-width: 600px;
                        justify-self: left;
                    }

                    .media-text {
                        text-align: left;
                        padding: 0px;
                        max-width: 700px;
                        justify-self: end;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .vesselsTwo {
        padding: 150px 50px 50px!important;
        display: grid;
        grid-template-columns: 100%;

        .vesselsTwoTitle {
            width: 50%;
        }

        .swiper {
            .swiper-slide {
                .card-media {
                    video {
                        width: 250px;
                        height: auto;
                    }

                    .media-text {
                        padding: 10px;

                        p {
                            max-height: 250px;
                            -webkit-line-clamp: 11;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .vesselsTwo {
        padding: 150px 20px 50px !important;
        background-image: none;
        .vesselsTwoTitle {
            width: 100%;
        }

        .swiper {
            margin-top: 20px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 425px) {
    .vesselsTwo {

        .vesselsTwoTitle {
            padding: 0px 20px;

            p {
                font-size: 14px !important;
            }
        }

        .swiper {
            margin-top: 20px;
            width: 100%;

            .swiper-button-next, .swiper-button-prev{
                top: 95%;

                img {
                    width: 30px;
                }
            }

            .swiper-slide {
                .card-media {
                    display: grid;

                    video {
                        width: 90%;
                    }

                    .media-text {
                        padding: 0px;

                        p {
                            padding: 0px;
                            -webkit-line-clamp: 6;
                        }
                    }
                }
            }
        }
    }
}