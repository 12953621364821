.aboutTwo {
    padding: 6rem 3rem;
    background-image: url('../../img/about/barco.webp');
    background-repeat: no-repeat;
    background-size: cover;

    .aboutTwoTitle {
        text-align: center;

        h1 {
            color: var(--cor-tertiary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToDown 1s ease forwards;
        }
    }

    .aboutTwoContent {
        display: flex;

        padding-top: 50px;
        justify-content: center;

        gap: 2%;

        .card1,
        .card2,
        .card3 {
            border: none;
            opacity: 0%;
            display: grid;
            grid-template-areas: 'card';
            cursor: pointer;

            justify-self: center;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(5.5px);
            background-color: rgba(225, 225, 225, 0.476);

            width: 325px;
            padding: 20px;

            img {
                transition: .3s ease;
                grid-area: card;
            }

            .cardText {
                width: 100%;
                grid-area: card;
                justify-content: center;
                padding: 0px 10px;
                transition: .3s ease;

                h2 {
                    margin-bottom: 5px;
                    font-size: 25px;
                    color: var(--cor-secondary);
                    font-weight: 700;
                }
            }
        }

        .card:hover {
            .cardText {
                scale: 1.1;
            }
        }

        .card1.visible {
            animation: halfToRight 1s .5s ease forwards;
        }

        .card2.visible {
            animation: slideToUp 1s ease forwards;
        }

        .card3.visible {
            animation: halfToLeft 1s .5s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .aboutTwo {
        .aboutTwoContent {

            .card1,
            .card3 {
                .cardText {
                    width: 300px;
                    padding: 0px 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .aboutTwo {
        padding: 6rem 2rem;

        .aboutTwoContent {
            gap: 10px;

            .card {
                .cardText {
                    padding: 0px;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .aboutTwo {
        .aboutTwoContent {
            display: grid;
            grid-template-columns: 100%;

            .card {
                width: 100%;

                .cardText {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .aboutTwo {
        padding-top: 40px;
        padding-bottom: 40px;

        .aboutTwoContent {
            padding: 20px 0px 0px;
            grid-template-columns: 100%;

            h2 {
                font-size: 18px !important;
            }
        }
    }
}