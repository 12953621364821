.homeFeed {
    padding: 100px 150px 100px 150px;
    width: 100vw;

    display: grid;
    justify-content: center;
    background-color: var(--cor-secondary);

    .homeFeedTitle {
        text-align: center;
        padding: 0px 25%;

        h1 {
            color: white;
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToUp 1s ease forwards;
        }
    }

    .homeFeed2 {
        padding-top: 50px !important;
        padding-top: 100px;
        width: 100vw;
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: center;
        gap: 20px;

        .card1, .card3{
            scale: .9;
        }
        .card{
            background: none;
            border: none;

            .logo{
                width: fit-content;
                border-radius: 50%;
                display: grid;
                margin-left: 20px;
                margin-bottom: 5px;

                img{
                    align-self: center;
                    justify-self: center;
                    height: 40px;
                    width: 40px;
                }
            }
            .instaList{
                display: flex;
                grid-template-rows: 100%;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;

                .instaList1{
                    display: flex;
                    gap: 5px;

                    img{
                        width: 25px;
                        height: 25px;
                    }
                }
                .save{
                    width: 25px;
                    height: 25px;
                    justify-self: end;
                }
            }
        }
        .card-media {
            display: grid;
            gap: 10px;
            width: 100%;
            max-width: 400px;
            transition: .5s ease;
            grid-template-areas: 'card';
            cursor: pointer;
            background-color: #00301b;
            border-radius: 30px;

            img {
                grid-area: card;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 100%;
                transition: .5s ease;
                border-radius: 30px;
            }

            .media-text {
                border-radius: 30px;
                grid-area: card;
                display: grid;
                transition: 0.6s ease-in-out;
                align-self: center;
                height: fit-content;
                min-width: 0px;
                max-width: 100%;
                padding: 0px 5px;
                opacity: 0%;
                transition: .5s ease;

                p {
                    text-align: center;
                    align-self: center;
                    font-size: 13px;
                    font-weight: 500;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin: 0px;
                    min-width: 0px;
                    display: -webkit-box;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    white-space: normal;
                    overflow-wrap: normal;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 5;
                    line-height: 1.3;
                    justify-self: center;
                    max-height: 100%;
                    z-index: 5;
                    color: rgb(235, 235, 235);
                    font-weight: 400;
                    transition: 0.6s ease-in-out;
                }

                a {
                    font-size: 14px;
                    width: 100%;
                    color: rgb(204, 204, 204);
                    text-align: center;
                    z-index: 12;
                }
            }
        }
        .card-media:hover{
            img{
                opacity: 0%;
            }
            .media-text{
                opacity: 100%;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .homeFeed {
        padding: 80px 50px 50px;
        background-position: 100% 50%;

        .homeFeed2 {
            padding-top: 50px;

            .card{
                padding: 0px !important;
                .logo{
                    img{
                        width: 30px;
                        height: 30px;
                    }
                }
                .instaList{
                    .instaList1{
                        img{
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .save{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .card-media {
                padding-top: 0px;
                gap: 20px;
                max-width: 300px;
            }
            .media-text {
                p {
                    font-size: 12px !important;
                }

                a {
                    font-size: 12px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .homeFeed {
        padding: 50px 0px 0px;
        height: fit-content !important;

        .homeFeedTitle {
            padding: 0px 50px !important;
        }

        .homeFeed2 {
            padding: 50px;

            .card-media {
                padding-top: 5px;
                gap: 35px;

                .media-text {
                    p {
                        font-size: 12px !important;
                        -webkit-line-clamp: 4 !important;
                    }

                    a {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .homeFeed {
        .homeFeed2{
            grid-template-columns: 100%;

            .card{
                width: fit-content;
                justify-self: center;
            }
            .card-media{
                max-width: 400px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .homeFeed {
        .homeFeedTitle {
            padding: 0px 20px !important;
        }
        .homeFeed2{
            padding: 20px !important;
            gap: 0px;
            height: fit-content;

            .card1, .card3{
                scale: 1;
            }
            .instaList{
                position: relative; 
                bottom: 120px;
            }
            .card-media{
                grid-template-rows: 2;
                gap: 40px;
                max-width: 100%;

                .media-text{
                    grid-row-start: 2;
                    grid-row-end: 3;
                    opacity: 100%;
                    padding: 0px 10px;

                    p{
                        text-align: left;
                    }
                    a{
                        text-align: left;
                    }
                }
            }
            .card-media:hover{
                img{
                    opacity: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .homeFeed {
        .homeFeed2 {
            .swiper {
                width: 85% !important;
            }

            .phone {
                img {
                    width: 100%;
                }
            }
        }
    }
}