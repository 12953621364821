.vesselsOne {
    opacity: 0%;
    padding: 100px;
    display: flex;
    gap: 0px;

    video{
        margin-right: 30px;
        width: 400px;
        opacity: 100% !important;

    }

    img {
        opacity: 0%;
    }

    img.visible {
        animation: halfToRight 1s .2s ease forwards;
    }

    .vesselsText {
        position: relative;
        display: grid;
        gap: 10px;
        height: fit-content;
        margin-left: 100px;

        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            max-width: 650px;
            opacity: 0%;

            span {
                color: var(--cor-secondary);
            }
        }

        h1.visible {
            animation: halfToRight 1s .3s ease forwards;
        }

        h2 {
            color: var(--cor-secondary);
            font-weight: 600;
            opacity: 0%;
        }

        h2.visible {
            animation: halfToRight 1s .4s ease forwards;
        }

        p {
            font-size: 18px;
            max-width: 100%;
            opacity: 0%;
            margin: 0px;
        }

        p.visible {
            animation: halfToRight 1s .5s ease forwards;
        }
    }
}

.vesselsOne.visible {
    animation: fadeIn 2s ease forwards;
}

@media screen and (max-width: 1600px) {
    .vesselsOne {
        padding: 100px;

        .vesselsText {
            right: 0px;

            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .vesselsOne {
        padding: 100px 50px;

        .vesselsText {
            margin-left: 0px;

            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .vesselsOne {
        padding: 100px 50px 50px;
        display: grid;
        grid-template-columns: 100%;
        align-items: center;

        .vesselsText {
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }
}

@media screen and (max-width: 900px) {
    .vesselsOne {
        .img1 {
            width: fit-content;

            img {
                width: 300px;
            }
        }

        .vesselsText {
            width: 100%;

            h1 {
                width: 100%;
            }

            p {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .vesselsOne {
        padding: 50px 20px;
        .img1 {
            img {
                width: 100%;
                min-width: 150px;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .vesselsOne {
        grid-template-columns: 100%;
        .img1{
            width: 100%;
        }

        .vesselsText {
            p {
                font-size: 14px;
            }
        }
    }
}