.homeWeather{
    padding: 100px 0px 0px;
    background-color: rgb(245, 245, 245);

    .homeWeatherTitle{
        display: grid;
        justify-content: center;
        text-align: center;

        h1{
            color: var(--cor-primary);
            font-weight: 600;
        }
        .underline{
            justify-self: center;
            width: 150px;
            height: 2px;
            background-color: var(--cor-primary);
        }
    }
    .swiper{
        padding: 50px 150px;

        .swiper-pagination-bullet-active{
            background-color: var(--cor-primary);
        }
        .swiper-slide{
            .card{
                padding: 30px;
                box-shadow: 0 8px 32px 0 #38383936;
                border: 0px;
                background-color: white;
                display: grid;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-radius: 20px;

                .weekDay, .date{
                    font-size: 18px;
                    color: var(--cor-secondary);
                    font-weight: 700;
                    margin: 0px;
                }         
                .date{
                    font-size: 16px;
                }     
                .climate{
                    padding: 10px 0px;
                    display: flex;
                    gap: 40px;
                    justify-content: center;

                    .min, .max{
                        margin: 0px;
                        color: rgb(0, 0, 0);

                        span{
                            color: var(--cor-primary);
                            font-weight: 700;
                        }
                    }
                }  
                .current{
                    color: rgb(0, 0, 0);

                    span{
                        font-size: 30px;
                        color: rgb(0, 113, 206);
                        font-weight: 700;
                    }
                }
                .moisture, .wind{
                    color: var(--cor-secondary);
                    font-weight: 700;
                    font-size: 15px;
                    margin: 0px 0px 5px 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px){
    .homeWeather{
        .swiper{
            padding: 50px 50px;

            .swiper-slide{
                .card{
                    .current{
                        span{
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .homeWeather{
        padding-bottom: 20px;
        .swiper{
            padding: 50px 20px;
        }
    }
}