.homeServices {
    padding: 50px 50px 50px 150px;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    background-color: var(--cor-secondary);
    overflow: hidden;
    gap: 50px;
    opacity: 0%;

    .homeServicesText {
        h1 {
            color: var(--cor-white);
            font-weight: 700;
            opacity: 0%;

            span {
                color: #00ad25;
            }
        }

        h1.visible {
            animation: halfToRight 1s ease forwards;
        }

        p {
            padding-top: 15px;
            max-width: 500px;
            line-height: 28px;
            opacity: 0%;
            color: var(--cor-white);
        }

        p.visible {
            animation: halfToRight 1s .1s ease forwards;
        }

        button {
            padding: 10px 20px;
            background-color: #00ad25;
            border: none;
            border-radius: 10px;
            color: var(--cor-dark);
            font-weight: 600;
            width: 200px;
        }

        img {
            padding-top: 10px;
            opacity: 0%;
        }

        img.visible {
            animation: halfToRight 1s .2s ease forwards;
        }
    }

    .homeServicesItem {

        display: flex;
        align-items: center;

        .item {
            padding: 1rem;
            border-radius: 10px;
            width: 320px;
            box-shadow: 0 4px 18px rgba(0, 0, 0, 0.727);
            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        }

        .item:hover {
            transform: scale(1.1);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

        .one {
            background-color: #0f6641;
            margin: 1rem;
        }

        .two {
            background-color: #004b2a;
            margin: 2rem 1rem;
        }

        .three {
            background-color: #1c6440;
            margin: 1rem;
        }

        h2 {
            color: var(--cor-green);
            font-weight: 700;
        }

        p {
            color: var(--cor-white);
        }


    }

    .swiper {
        max-width: 650px;
        height: 500px;
        opacity: 0%;

        .swiper-button-next {
            left: 415px;
        }

        .swiper-button-prev {
            left: 0px;
        }

        .swiper-slide-prev {
            scale: .7;
            opacity: 0%;
        }

        .swiper-slide-next {
            scale: .7;
            position: relative;
            right: 100px;
            z-index: 2 !important;
            opacity: 100% !important;
        }

        .swiper-slide-active {
            z-index: 3 !important;
            transition: .5s ease;
            opacity: 100% !important;
        }

        .swiper-slide {
            transition: .5s ease;
            z-index: 1;
            height: 100%;
            opacity: 0%;

            .blue {
                background-image: url('../../img/home/DJI_0049.webp');
                background-repeat: no-repeat;
                background-size: 450px 500px;
            }

            .orange {
                background-image: url('../../img/home/DJI_0049.webp');
                background-repeat: no-repeat;
                background-size: 450px 500px;
            }

            .card-bg {
                width: 450px;
                height: 100%;
                display: grid;
            }

            .card {
                padding: 200px 50px 20px !important;
                background-color: rgba(255, 255, 255, 0);
                border: none;
                display: grid;
                height: fit-content;
                justify-content: center;
                align-self: center;
                gap: 10px;
                text-align: center;

                img {
                    height: auto;
                    width: 100px;
                    justify-self: center;
                }

                h3 {
                    margin: 0px;
                    font-size: 18px;
                    color: white;
                }

                p {
                    margin: 0px;
                    color: white;
                    font-size: 14px;
                    font-weight: 300;
                }
            }
        }
    }

    .swiper.visible {
        animation: halfToRight 1s .6s ease forwards;
    }
}

.homeServices.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1440px) {
    .homeServices {
        padding: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .homeServices {
        padding: 0px 50px;

        .swiper {
            justify-self: right;
            width: 400px;

            .swiper-button-next {
                left: 370px;
            }

            .swiper-button-next,
            .swiper-button-prev {
                img {
                    width: 30px;
                    height: auto;
                }
            }

            .swiper-slide-next {
                right: 0px;
            }

            .swiper-slide {
                width: 400px !important;

                .card-bg {
                    width: 400px;
                }

                .blue,
                .orange {
                    background-size: 400px;
                }

                .card {
                    width: 400px;
                    padding-bottom: 80px !important;

                    h3 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 12px;
                    }

                    img {
                        height: auto;
                        width: 70px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeServices {
        padding: 50px;
    }

    .homeServicesItem {
        flex-direction: column;
    }
}

@media screen and (max-width: 900px) {
    .homeServices {
        grid-template-columns: 100%;
        gap: 10px;

        .homeServicesText {
            p {
                max-width: 100%;
            }
        }

        .swiper {
            width: 600px;
            height: 450px;

            .swiper-slide {
                .card {
                    padding-bottom: 20px !important;
                }
            }

            .swiper-slide-next {
                right: 150px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .homeServices {
        .swiper {
            width: 400px;

            .swiper-slide-next {
                right: 0px;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .homeServices {
        padding: 50px 0px;

        .homeServicesText {
            padding: 0px 20px;
        }

        .swiper {
            width: 350px;
            height: 400px;

            .swiper-button-next {
                left: 320px;
            }

            .swiper-slide {
                width: 350px !important;

                .orange,
                .blue {
                    background-size: 350px;
                }

                .card-bg {
                    width: 350px;
                }

                .card {
                    width: 350px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeServices {
        padding: 30px 0px;

        .homeServicesText {
            p {
                font-size: 14px;
            }

            img {
                width: 150px;
                height: auto;
            }
        }

        .homeServicesItem {
            p {
                font-size: 14px !important;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .homeServices {
        padding: 30px 10px;

        .homeServicesText {
            padding: 0px;
        }

        .swiper {
            width: 100%;
            height: 380px;

            .swiper-button-next {
                left: 300px;
            }

            .swiper-slide {
                width: 100% !important;
                height: 380px;

                .card {
                    width: 100%;
                    padding: 0px 30px 40px !important;
                }

                .card-bg {
                    width: 100%;
                    height: 380px;
                }

                .blue,
                .orange {
                    background-size: 100% 380px;
                }
            }
        }
    }
}