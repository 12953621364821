.cookies{
    position: fixed;
    inset: auto 10px 20px auto;
    background-color: rgb(243, 243, 243);
    border: 1px solid var(--cor-secondary);
    border-radius: 10px;
    padding: 30px 20px;
    z-index: 9999;
    max-width: 560px;
    opacity: 0%;
    animation: halfToUp 1s 10s ease forwards;
    
    p{
        color: var(--cor-tertiary);
        font-weight: 600;
    }
    button{
        border: 1px solid var(--cor-secondary);
        border-radius: 5px;
        background-color: var(--cor-secondary);
        color: white;
        padding: 5px 20px;
    }
}

@media screen and (max-width: 680px){
    .cookies{
        max-width: 80%;
        padding: 20px 20px;

        p{
            font-size: 14px;
            margin-bottom: 5px;
        }
        button{
            font-size: 14px;
        }
    }
}