.servicesOne {
    padding: 200px 0px 50px;
    display: grid;
    grid-template-columns: 60% 50%;
    background-image: linear-gradient(45deg, #001e11e8, #001e11d1, #002012a0), url('../../img/about/img-4.webp');
    background-repeat: no-repeat;
    background-size: cover;
    height: 75vh;

    .servicesOneText {
        margin-top: 2rem;

        h1 {
            color: var(--cor-tertiary);
            font-size: 30px !important;
            font-weight: 700;
            padding-left: 150px;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToRight 2s ease forwards;
        }

        span {
            color: var(--cor-secondary);
        }

        p {
            padding-top: 20px;
            width: 750px;
            line-height: 25px;
            font-size: 16px;
            padding-left: 150px;
            opacity: 0%;
            color: var(--cor-white);
        }

        p.visible {
            animation: slideToRight 2s .2s ease forwards;
        }

        img {
            position: relative;
            margin: 50px 0px 0px -50px;
            opacity: 0%;
            transition: .5s ease;
            width: 400px;
            cursor: pointer;
        }

        img:hover {
            scale: 1.08;
        }

        img.visible {
            animation: slideToLeft 2s .4s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .servicesOne {

        .servicesOneText {
            h1 {
                padding-left: 100px;
            }

            p {
                width: 650px;
                font-size: 16px !important;
                padding-left: 100px;
            }

            img {
                margin: 0px;
                width: 300px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .servicesOne {
        padding: 200px 0px 50px;

        .servicesOneText {
            h1 {
                padding-left: 50px;
                font-size: 25px !important;
            }

            p {
                padding-left: 50px;
                width: 100%;
                max-width: 650px;
                padding-right: 0px;
            }

            img {
                width: 300px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .servicesOne {
        .servicesOneText {
            img {
                width: 100px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .servicesOne {
        .servicesOneText {
            p {
                line-height: 35px;
            }
        }
    }

}

@media screen and (max-width: 800px) {
    .servicesOne {
        .servicesOneText {
            p {
                line-height: 30px;
            }

            img {
                width: 100px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .servicesOne {
        grid-template-columns: 100%;
        padding: 150px 50px 50px;

        .servicesOneText {
            h1 {
                padding: 0px;
            }

            p {
                padding: 0px;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .servicesOne {
        .servicesOneImg {
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .servicesOne {
        padding: 150px 20px 50px;

        .servicesOneText {
            align-self: center;

            h1 {
                font-size: 22px !important
            }

            p {
                font-size: 14px !important;
            }
        }
    }
}