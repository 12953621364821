@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  scroll-behavior: smooth !important;
  text-decoration: none !important;
  font-family: 'Montserrat', sans-serif;
  list-style: none;
  letter-spacing: -.5px;
}

body {
  background-color: rgb(255, 255, 255);
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 10px !important;
  opacity: 0%;
}

::-webkit-scrollbar-thumb {
  background-color: var(--cor-tertiary);
}

::-webkit-scrollbar-track {
  background-color: #002a7200;
}

:root {
  --cor-primary: #0f6641;
  --cor-secondary: #00301b;
  --cor-tertiary: #009756;
  --cor-green: #00ad25;
  --cor-white: #fff;
  --cor-dark: #141414;
}


h1 {
  font-size: 30px !important;
}

h2 {
  font-size: 22px !important;
}

@media screen and (max-width: 1600px) {
  h1 {
    font-size: 25px !important;
  }

  h2 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1440px) {
  h1 {
    font-size: 25px !important;
  }

  h2 {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 425px) {
  body {
    overflow-x: hidden;
  }

  h1 {
    font-size: 22px !important;
  }

  h2 {
    font-size: 16px !important;
  }
}