.servicesThree {
    background-color: var(--cor-white);
    padding: 100px 50px 50px;

    .servicesThreeTitle {
        display: grid;
        justify-content: center;

        h2 {
            color: var(--cor-primary);
            font-size: 30px !important;
            font-weight: 700;
            text-align: center;
        }
    
        h2.visible {
            animation: slideToRight 2s ease forwards;
        }

        img{
            position: relative;
            top: -15px;
            opacity: 50%;
        }
    }

    .swiper{
        max-width: 1400px;
        .swiper-pagination-bullet-active{
            background-color: var(--cor-primary);
        }

        .service {
            padding: 3rem 3rem;
            display: flex;
            justify-content: center;
            gap: 100px;
            align-items: center;
    
            .servicesThreeText {
                h1 {
                    color: var(--cor-primary);
                    font-size: 30px !important;
                    font-weight: 700;
                    opacity: 0%;
                }
        
                h1.visible {
                    animation: slideToRight 2s ease forwards;
                }
        
                span {
                    color: var(--cor-secondary);
                }
        
                p {
                    padding-top: 20px;
                    width: 750px;
                    line-height: 25px;
                    font-size: 16px;
                    max-width: 600px;
                    opacity: 0%;
                }
        
                p.visible {
                    animation: slideToRight 2s .2s ease forwards;
                }
            }
        
            img {
                transition: .5s ease;
                width: 400px;
                cursor: pointer;
                border-radius: 10px;
            }
        
            img:hover {
                scale: 1.08;
            }
        
            img.visible {
                animation: slideToLeft 2s .4s ease forwards;
            }
        }
    }

    
}

@media screen and (max-width: 1600px) {
    .servicesThree {
        .servicesThreeText {
            p {
                width: 650px;
                font-size: 16px !important;
            }

            img {
                margin: 0px;
                width: 300px;
            }
        }
        .swiper{
            height: fit-content;
            max-width: 100%;
            .service{
                gap: 50px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .servicesThree{
        padding: 100px 0px 50px;
        
        .servicesThreeTitle{
            h2{
                font-size: 25px !important;
            }
        }
        .swiper{
            .service{
                .servicesThreeText{
                    max-width: 400px;

                    p{
                        max-width: 400px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .servicesThree {
        .swiper{
            .service{
                .servicesThreeText{
                    h1{
                        font-size: 25px !important;
                    }
                    p{
                        font-size: 14px !important;
                        padding: 0px;
                    }
                }
                img{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .servicesThree {
        .swiper{
            .service{
                display: grid;
                gap: 20px;

                .servicesThreeText{
                    width: 100%;
                    max-width: 100%;

                    p{
                        max-width: 100%;
                        width: 100%;
                    }
                }
                img{
                    max-width: 400px;
                }
            }
        }
    }
}