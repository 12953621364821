.navbarWhite{
    background-color: #0f6640ea;

    .navbar{
        transition: .5s ease;
    }
    
    .nav-container {
        margin: 0px 0px 0px 100px;
        padding: 0px 126px;
        width: 100%;
        display: flex;
    }
    
    .container-fluid {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 10px;
    }
    
    .flex-content-nav {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        max-height: 75px;
    
        .btn-danger {
            color: var(--cor-white);
        }
    }
    
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        transform: translate(0px, 44px) !important;
    }
    
    .navbar-toggler {
        float: right;
    }
    
    .scrolled {
        background-color: #0f6640ea;
        backdrop-filter: blur(5.5px);
    }
    
    .offcanvas-body {
        display: flex;
        padding: 10px;
    }
    
    .flex-button-nav {
        display: flex;
    }
    
    .btnServices,
    .btnAbout {
        border-radius: 5px 0px 0px 5px !important;
        transition: .5s ease;
    }
    
    .btnServices:hover,
    .btnAbout:hover {
        background-color: #002575 !important;
    }
    
    .btnServicesToggle,
    .btnAboutToggle {
        transition: .5s ease;
        border-radius: 0px 5px 5px 0px !important;
    }
    
    .btnServicesToggle:hover,
    .btnAboutToggle:hover {
        background-color: #002575 !important;
    }
    
    .btn-group {
        padding: 0px 16px;
        transition: 0.2;
    }
    
    .btn-group .btn {
        padding: 8px;
        border: 1px solid #ffffff00;
    }
    
    .btn-group:hover .btn {
        color: var(--cor-secondary);
        border: 1px solid var(--cor-secondary);
        border-radius: 5px;
    }
    
    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
    
        z-index: 9999;
    }
    
    .navbar-nav {
        gap: 20px;
    }
    
    .navLogo {
        filter: brightness(100);
        padding: 0px;
        margin: 0px;
        animation: slideToDown 1s .5s ease forwards;
        opacity: 0%;
    
        img{
            width: 190px !important;
            top: 0px;
        }
    }
    
    a {
        color: white;
    }
    
    a.nav-link.active {
        color: white !important;
    }
    
    a.nav-link.active:hover {
        color: var(--cor-secondary) !important;
    }
    
    .btn-group .btn {
        background: none;
    
        color: white;
    }
    
    button {
        margin: 0px !important;
    }
    
    .nav-item {
        align-self: center;
    
        a {
            transition: .5s ease;
        }
    }
    
    .nav-item:hover {
        a {
            color: var(--cor-tertiary);
        }
    }
    
    .homeLink {
        opacity: 0%;
        animation: slideToDown 1s ease forwards;
    }
    
    .aboutLink {
        padding: 0px;
        opacity: 0%;
        animation: slideToDown 1s .1s ease forwards;
    }
    
    .servicesLink {
        padding: 0px;
        opacity: 0%;
        animation: slideToDown 1s .2s ease forwards;
    }
    
    .structureLink {
        padding: 0px;
        opacity: 0%;
        animation: slideToDown 1s .3s ease forwards;
    }
    
    .vesselsLink {
        opacity: 0%;
        animation: slideToDown 1s .5s ease forwards;
    }
    
    .contactLink {
        opacity: 0%;
        animation: slideToDown 1s .6s ease forwards;
    }
    
    .esgLink {
        opacity: 0%;
        animation: slideToDown 1s .7s ease forwards;
    }
    
    .blogLink {
        opacity: 0%;
        animation: slideToDown 1s .8s ease forwards;
    }
    
    .portalLink {
        padding: 0px;
        opacity: 0%;
        animation: slideToDown 1s .9s ease forwards;
        background-color: var(--cor-secondary);
    
        button {
            padding: 8px 30px !important;
        }
    
        .dropdown-toggle::after {
            margin-left: 5px !important;
        }
    }
    
    .portalLink:hover {
        button {
            color: var(--cor-primary) !important;
        }
    }
    
    .navbar-toggler {
        border: none;
    }
    
    .navbar-toggler-icon {
        background-image: url('../../icon/menu_branco.svg');
        width: 35px;
        height: 35px;
    }
    
    .navLogo2 {
        filter: brightness(100);
        display: none;
    }
    
    .dropdown-menu {
        border: 1px solid var(--cor-tertiary);
        background-color: #dfdfdf;
    
        a {
            display: block;
            height: 100%;
            width: 100%;
            color: var(--cor-primary);
            font-weight: 600;
            transition: .5s ease;
        }
    
        a:hover {
            color: var(--cor-tertiary);
        }
    }
}

@media screen and (max-width: 1700px) {
    .nav-container {
        margin: 0px 0px 0px 0px !important;
    }
}

@media screen and (max-width: 1600px) {
    .navbar {
        padding: 0px 10px;

        .navLogo {
            img {
                width: 190px;
            }
        }
    }

    .nav-container {
        margin: 0px;
        padding: 5px 20px;
    }

    .blogLink {
        padding-right: 20px !important;
    }
}

@media screen and (max-width: 1400px) {
    .navbar {

        a,
        button {
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 1199px) {
    .navbar {
        padding: 0px 5%;

        a {
            color: var(--cor-primary);
        }
    }

    .container-fluid {

        .navLogo2 {
            display: block;
        }
    }

    .flex-content-nav {
        grid-template-columns: 100%;
        padding-top: 50px;
        display: grid;
        height: fit-content;
        row-gap: 20px;
    }

    .btn-group .btn {
        padding: 0px;
    }

    .nav-container {
        padding: 0px;
    }

    a.nav-link.active,
    .btn-group .btn {
        color: var(--cor-primary) !important;
    }

    .blogLink {
        padding: 0px !important;
    }

    .navLogo {
        display: none;
    }

    .nav-item {
        display: grid;
    }

    .navbar-toggler {
        padding: 0px;
    }

    .structureLink {
        z-index: 1000;
    }

    .portalLink {
        .dropdown-menu {
            transform: translate(0px, 44px) !important;
        }
    }

    .dropdown-menu {
        z-index: 100;
        width: 100%;
        text-align: center;
        transform: translate(0px, 27px) !important;
    }
}

@media screen and (max-width: 425px) {
    .container-fluid {
        padding: 0px;
    }
}