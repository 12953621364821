.homeHeroBg {
    display: grid;
    grid-template-areas: 'hero';

    .bg-green{
        grid-area: hero;
        background-color: #00301ba9;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
    video{
        grid-area: hero;
        height: 100vh;
        width: 100%;
        object-fit: cover;
        z-index: 4;
    }
}

.homeHero {
    z-index: 5;
    grid-area: hero;
    height: 100vh;
    padding: 0px 150px;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    background-size: cover;

    .gptw {
        position: absolute;
        top: 40px;
        left: 50px;
        z-index: 1000;

        img {
            width: 60px !important;
            height: auto;
            opacity: 0%;
        }
    }

    .gptw.visible {
        img {
            animation: slideToDown 2s 1.5s ease forwards;
        }
    }

    .mySwiper {
        height: 100%;
        width: 100%;

        .swiper-slide-next,
        .swiper-slide-prev {
            opacity: 0%;
            animation: swiperPrev 1.5s ease forwards;
        }

        .swiper-slide-active {
            animation: swiperActive 1.5s ease forwards;
        }

        .swiper-slide {
            display: grid;
            align-items: center;

            img {
                justify-self: center;
                height: auto;
                width: 650px;
            }

            h1 {
                height: fit-content;
                color: white;
                margin-bottom: 50px;
                font-size: 35px !important; 
            }
        }
    }

    .swiperText {
        animation: halfToUp 1s ease forwards;
        height: 500px;

        h1 {
            width: fit-content;
            justify-self: center;
        }
    }

    .swiperImg {
        animation: halfToUp 1.3s ease forwards;
    }
}

@media screen and (min-width: 2000px) {
    .homeHero {
        img {
            width: 800px;
        }
    }
}

@media screen and (max-width: 1700px) {
    .homeHero {
        img {
            width: 450px;
        }
    }
}

@media screen and (max-width: 1600px) {
    .homeHeroBg {

        .arrow1,
        .arrow2 {
            width: 180px;
        }

        .arrow3,
        .arrow4 {
            width: 180px;
            margin-top: 450px;
        }

        .arrow5,
        .arrow6 {
            width: 70px !important;
            margin-right: 680px;
        }

        .arrow7,
        .arrow8 {
            width: 180px;
            margin-top: 500px;
        }
    }

    .homeHero {
        height: 100vh;
        background-size: 1600px;
        background-repeat: no-repeat;

        .swiper {
            .swiper-slide {
                height: fit-content;

                h1 {
                    margin-bottom: 100px;
                    font-size: 30px !important;
                }

                img {
                    width: 550px;
                }
            }
        }

        .gptw {
            top: 100px;
            left: 50px;
            z-index: 1000;
        }

        img {
            width: 450px;
            top: 120px;
        }
    }
}

@media screen and (max-width: 1440px) {
    .homeHeroBg {
        height: 100vh;

        .arrow7,
        .arrow8 {
            margin-bottom: 220px;
            margin-right: 0px;
            width: 200px;
        }

        .arrow3,
        .arrow4 {
            width: 200px;
            margin-bottom: 290px;
        }

        .arrow1,
        .arrow2 {
            width: 200px;
        }

        .arrow5,
        .arrow6 {
            margin-right: 550px;
        }
    }

    .homeHero {

        .gptw {
            img {
                width: 50px !important;
            }
        }

        .swiper{
            .swiper-slide {
                h1{
                    font-size: 25px !important;
                }
            }
        }
    }

}

@media screen and (max-width: 1200px) {
    .homeHero {
        .swiperImg {
            .swiper-slide {
                img {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeHeroBg {
        height: 95vh;

        .arrow1,
        .arrow2 {
            margin-top: 200px;
        }

        .arrow3,
        .arrow4 {
            margin-top: 420px;
            margin-left: 300px;
        }

        .arrow5,
        .arrow6 {
            margin-right: 400px;
        }
    }

    .homeHero {
        padding: 150px 5%;
        height: 100vh;

        img {
            bottom: 300px;
            width: 400px;
        }
    }
}

@media screen and (max-width: 900px) {
    .homeHeroBg {

        .arrow5,
        .arrow6 {
            margin-bottom: 600px;
            margin-right: 320px;
        }

        .arrow7,
        .arrow8 {
            margin-bottom: 230px;
        }
    }

    .homeHero {
        padding-right: 0px;
        display: grid;
        grid-template-columns: 40% 60%;

        h1 {
            height: fit-content;
            margin: 0px;
            top: 50px;
            width: 100%;
            font-size: 22px !important;
        }

        .swiper {
            .swiper-slide {
                img {
                    width: 100%;
                    max-width: 400px;
                    justify-self: right;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .homeHeroBg {
        height: 100vh;

        .arrow1,
        .arrow2 {
            display: none;
        }

        .arrow3,
        .arrow4 {
            margin-left: 100px;
        }

        .arrow5,
        .arrow6 {
            margin-top: 250px;
            margin-right: 100px;
        }

        .arrow7,
        .arrow8 {
            margin: 100px 0px auto auto;
        }
    }

    .homeHero {
        grid-template-columns: 100%;
        padding-bottom: 0px;
        height: 95vh;

        .gptw {
            left: auto;
            right: 37px;
        }

        .swiper {
            height: fit-content;

            .swiper-slide {
                justify-self: right;

                h1 {
                    margin-bottom: 0px;
                    justify-self: start;
                }
            }
        }

        .swiperImg {
            height: fit-content;
            top: -0px;
            right: 50px;
        }
    }
}

@media screen and (max-width: 550px) {
    .homeHeroBg {
        height: 100vh;

        .arrow3,
        .arrow4 {
            margin-left: 10px;
            margin-top: 400px;
        }

        .arrow5,
        .arrow6 {
            margin-right: 20px;
        }
    }

    .homeHero {
        .gptw {
            right: 27px;

            img {
                width: 40px !important;
            }
        }

        .swiperImg {
            right: 0px;

            .swiper-slide {
                img {
                    width: 350px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeHeroBg {
        height: 92vh;

        .arrow5,
        .arrow6 {
            margin: auto auto 320px 10px;
        }
    }

    .homeHero {
        height: 90vh;

        .swiperImg {
            top: -20px;
        }

        .swiper {
            .swiper-slide {
                h1 {
                    font-size: 20px !important;
                }

                img {
                    width: 85%;
                }
            }
        }
    }
}