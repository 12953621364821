.buttonUp{
    opacity: 0%;
    transition: .5s ease;
    z-index: 5000;

    .buttonText{
        z-index: 4999;
        position: fixed;
        bottom: 69px;
        right: 40px;
        padding: 5px 50px 5px 10px;
        border-radius: 5px;
        background-color: var(--cor-tertiary);
        opacity: 0%;

        p{
            font-weight: 700;
            font-size: 14px;
            margin: 0px;
            color: rgb(241, 241, 241);
        }
    }
    .buttonText.scrolled{
        animation: fadeInRight 1s 5s ease forwards;
    }
    button{
        position: fixed;
        bottom: 70px;
        right: 10px;
        background-color: rgba(255, 255, 255, 0);
        border-radius: 50%;
        padding: 0px;
        border: 2px solid var(--cor-primary);
        transition: 1s ease;
        display: grid;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.199);
        z-index: 5000;
    
        .buttonTop{
            align-self: center;
            justify-self: center;
            padding: 2px;
            border-radius: 50%;
            width: 65px;
            height: 65px;
            background-color: var(--cor-primary);
            display: grid;
            overflow: hidden;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: .5s ease;
            scale: 1.1;
        
            .img1{
                width: 50px;
                transition: .5s ease;
                transform: translateY(100%);
            }
            .img2{
                width: 50px;
                transition: .5s .1s ease;
                transform: translateY(200%);
            }
            .img3{
                width: 50px;
                transition: .5s .2s ease;
                transform: translateY(300%);
            }
        }
    }
    button:hover{
        .buttonTop{
            scale: .9;
            .img1{
                transform: translateY(20%);
                scale: .9;
            }
            .img2{
                transform: translateY(10%);
                scale: .9;
            }
            .img3{
                transform: translateY(0%);
                scale: .9;
            }
        }
    }

}
.buttonUp.scrolled{
    opacity: 100%;
}

@media screen and (max-width: 1440px){
    .buttonUp{
        button{
            bottom: 57px;
            .buttonTop{
                width: 50px;
                height: 50px;
                
                img{
                    width: 40px !important;
                }
            }
        }
        .buttonText{
            padding: 3px 40px 3px 8px;
            right: 35px;
            bottom: 57px;
        }
    }
}

@media screen and (max-width: 768px){
    .buttonUp{
        .buttonText{
            display: none;
        }
        button{
            bottom: 51px;
            .buttonTop{
                width: 45px;
                height: 45px;

                .img1, .img2, .img3{
                    width: 36px !important;
                }
            }
        }
    }
}