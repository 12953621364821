.homeContact {
    margin: 4rem;
    padding: 2rem 4rem;
    display: grid;
    justify-content: center;
    text-align: center;
    background-color: #fafafa;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.452);
    border-radius: 10px;

    .contactTitle {
        padding: 50px 20px 0px;

        h1 {
            font-size: 40px !important;
            font-weight: 800;
            color: var(--cor-primary);
            opacity: 0%;
        }

        h1.visible {
            animation: halfToUp 1s .1s ease forwards;
        }
    }

    .contactContent {
        display: grid;
        grid-template-columns: auto;

        .arrows1,
        .arrows2 {
            width: fit-content;
            position: absolute;
            display: grid;

            img {
                opacity: 0%;
            }

            .img1 {
                animation: arrows 2s ease infinite;
            }

            .img2 {
                animation: arrows 2s .3s ease infinite;
            }

            .img3 {
                animation: arrows 2s .6s ease infinite;
            }

            .img4 {
                animation: arrows 2s .9s ease infinite;
            }
        }

        .arrows1 {
            left: 3%;
            transform: translateY(500px);
        }

        .arrows2 {
            right: 3%;
            transform: translateY(80px);
        }

        .text {
            display: grid;
            justify-items: center;

            p {
                color: var(--cor-dark);
                font-size: 18px;
                font-weight: 400;
                opacity: 0%;
            }

            p.visible {
                animation: halfToUp 1s .2s ease forwards;
            }

        }

        a {
            position: relative;
            padding: 20px 50px;
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            width: fit-content;
            overflow: hidden;
            border-radius: 40px;
            align-self: center;
            justify-self: center;

            span {
                position: relative;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: normal;
                z-index: 1;
            }

            .liquid {
                position: absolute;
                top: -130px;
                left: 0;
                width: 310px;
                height: 300px;
                background: var(--cor-primary);
                box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
                transition: .5s;
            }

            .liquid::after,
            .liquid::before {
                content: '';
                width: 200%;
                height: 200%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -75%);
                background: #004629;
            }

            .liquid::before {
                border-radius: 45%;
                background: #004629;
                animation: animate 5s linear infinite;
            }

            .liquid::after {
                border-radius: 40%;
                background: rgba(20, 20, 20, .5);
                animation: animate 10s linear infinite;
            }
        }

        a:hover {
            .liquid {
                top: -180px;
            }
        }

        @keyframes animate {
            0% {
                transform: translate(-50%, -75%) rotate(0deg);
            }

            100% {
                transform: translate(-50%, -75%) rotate(360deg);
            }
        }
    }
}

.homeContact.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (min-width: 2000px) {
    .homeContact {
        background-size: 100% 90%;
        background-position: 50% 50%;
    }
}

@media screen and (max-width: 1600px) {
    .homeContact {


        .contactTitle {
            h1 {
                font-size: 35px !important;
            }
        }

        .contactContent {
            .text {
                padding: 20px 0px 0px;

                p {
                    font-size: 16px;
                }

                .contactBtn {
                    transform: translateY(120px);

                    img {
                        width: 300px;
                    }

                    .button {
                        p {
                            top: 20px;
                        }

                        .underline {
                            bottom: 15px;
                        }
                    }
                }
            }

            .arrows1 {
                transform: translateY(400px);

                img {
                    width: 150px;
                }
            }

            .arrows2 {
                transform: translateY(30px);

                img {
                    width: 150px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .homeContact {
        .contactTitle {
            h1 {
                font-size: 30px !important;
            }
        }

        .contactContent {
            .arrows2 {
                transform: translateY(0px);
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeContact {
        .contactContent {
            .text{
                a{
                    span{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 550px) {
    .homeContact {
        margin: 20px;
        .contactTitle {
            padding: 0px;
            h1 {
                font-size: 25px !important;
            }
        }

        .contactContent {
            .text {
                p {
                    min-width: 300px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeContact {
        .contactTitle{
            h1{
                font-size: 22px !important;
            }
        }
        .contactContent {
            .text {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}