.homeAbout {
    padding: 50px 150px 80px;
    display: flex;
    margin-bottom: 1rem;
    gap: 40px;

    .homeAboutText {
        padding-top: 50px;

        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToRight 1s ease forwards;
        }

        span {
            font-size: 30px;
            color: var(--cor-tertiary);
        }

        span.visible {
            animation: slideToRight 1s .3s ease forwards;
        }

        p {
            opacity: 0%;
            padding-top: 10px;
            font-size: 18px;
        }

        p.visible {
            animation: slideToRight 1s .3s ease forwards;
        }
    }

    .homeAboutImg {
        display: flex;


        .img1 {
            position: relative;
            top: 20px;
            border-radius: 10px 0px 10px 10px;
        }

        .img2 {
            position: relative;
            bottom: 20px;
            border-radius: 10px 10px 10px 0px;
        }
    }
}

@media screen and (max-width: 1600px) {
    .homeAbout {
        padding: 20px 50px 50px 50px;
    }
}

@media screen and (max-width: 1400px) {
    .homeAbout {
        .homeAboutText{
            h1{
                span{
                    font-size: 25px !important;
                }
            }
            p{
                font-size: 16px ;
            }
        }
        .homeCertificationsCards {
            .card {
                padding: 0px 10px !important;

                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        .homeCertificationsText {
            p {
                font-size: 16px !important;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .homeAbout {
        flex-direction: column;
        margin-bottom: 0px;

        .homeAboutImg {
            margin-top: 0px !important;
            flex-direction: column;

            .card {
                img {
                    width: 70px;
                    height: 70px;
                }
            }
        }
        .homeAboutText{
            padding-top: 20px;
        }
    }
}

@media screen and (max-width: 500px) {
    .homeAbout {
        padding: 20px;

        .homeAboutImg{
            img{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeAbout {
        .homeAboutText{
            h1{
                span{
                    font-size: 22px !important;
                }
            }
            p{
                font-size: 14px !important
            }
        }
    }
}