#sub-nav {
    .allPosts {
        opacity: 0%;
        animation: slideToDown 1s .2s ease forwards;
    }

    .search {
        opacity: 0%;
        animation: slideToDown 1s .3s ease forwards;
    }

    .blogBtn {
        opacity: 0%;
        animation: slideToDown 1s .4s ease forwards;
    }

    .navbar {
        position: fixed;
        top: 111px;
        width: 100%;
        z-index: 99;

        background-color: #0f6640ea;

        padding: 10px 30px;
    }

    .navbar-brand {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
    }

    form {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 20px;

        width: 100%;
    }

    input {
        width: 400px;
        height: 35px;

        border-radius: 5px;
        border: none;
    }

    input::placeholder {
        color: var(--cor-secondary);
        font-weight: 500;
    }

    .input-group-text {
        display: flex;
        align-items: center;

        width: 50px;

        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;

        position: relative;
        right: 60px;

        color: var(--cor-secondary);

        text-align: center;
        white-space: nowrap;

        border: none;
        background-color: transparent;
    }

    button {
        background-color: var(--cor-secondary);
        width: 180px;
        height: 35px;
        font-size: 16px;
        border: none;
    }

}

@media screen and (max-width: 1600px) {
    #sub-nav {
        .navbar {
            top: 95px;
        }
    }
}

@media screen and (max-width: 1200px) {
    #sub-nav{
        .navbar{
            top: 101px;
        }
    }
}

@media screen and (max-width: 910px) {
    #sub-nav {

        .ms-4,
        .me-2 {
            margin: 0px !important;
        }

        form {
            justify-content: space-between;
        }

        input {
            width: 300px;
        }

        button {
            max-width: 120px;
        }
    }
}

@media screen and (max-width: 710px) {
    #sub-nav {
        .input-group-text {
            right: 20px;
        }

        form {
            grid-template-columns: 100%;
            display: grid;
            row-gap: 10px;
            justify-content: center;
        }

        .allPosts {
            grid-row-start: 3;
            grid-row-end: 4;
            margin: 0px;
            text-align: center;
        }

        input {
            width: 100%;
            max-width: 300px;
            transform: translateX(25px);
        }

        .search {
            justify-self: center;
        }

        .blogBtn {
            justify-self: center;
        }

        button {
            width: 100%;
        }

    }
}

@media screen and (max-width: 425px) {
    #sub-nav {
        .navbar {
            padding: 10px 0px;
        }

    }
}