.homeTides {
    padding: 50px 150px 150px;
    display: grid;
    justify-content: center;
    text-align: center;
    opacity: 0%;
    overflow: hidden;
    background-image: linear-gradient(180deg, rgb(245, 245, 245), rgb(255, 255, 255));

    .waves2 {
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;
        height: 250px;
        background-color: rgba(255, 255, 255, 0);
        transition: 500ms;
    }
    
    .waves2::before,
    .waves2::after {
        content: '';
        position: absolute;
        width: 300vw;
        height: 300vw;
        top: -65vw;
        left: 50%;
        transform: translate(-50%, -75%);
    }
    
    .waves2::before {
        border: 1px solid #00301b3b;
        border-radius: 44%;
        background: #00361f00;
        animation: waves 8s linear infinite;
    }
    
    .waves2::after {
        border: 1px solid #00301b33;
        border-radius: 44%;
        background: #00301b00;
        animation: waves 15s linear infinite;
    }    

    .homeTidesTitle {
        padding: 0px 20px 20px;

        h1 {
            font-weight: 700;
            color: var(--cor-primary);
            opacity: 0%;
        }

        h1.visible {
            animation: halfToUp 1s .1s ease forwards;
        }
    }

    .homeTidesContent {
        display: grid;
        justify-content: center;

        p {
            max-width: 450px;
            color: rgb(0, 0, 0);
            font-size: 18px;
            font-weight: 300;
            opacity: 0%;
        }

        p.visible {
            animation: halfToUp 1s .2s ease forwards;
        }

        a {
            position: relative;
            padding: 20px 50px;
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            width: fit-content;
            overflow: hidden;
            border-radius: 40px;
            align-self: center;
            justify-self: center;

            span {
                position: relative;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: normal;
                z-index: 1;
            }

            .liquid {
                position: absolute;
                top: -130px;
                left: 0;
                width: 300px;
                height: 300px;
                background: var(--cor-primary);
                box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
                transition: .5s;
            }

            .liquid::after,
            .liquid::before {
            content: '';
                width: 200%;
                height: 200%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -75%);
                background: #004629;
            }
            .liquid::before {
                border-radius: 45%;
                background: #004629;
                animation: animate 5s linear infinite;
            }
            .liquid::after {
                border-radius: 40%;
                background: rgba(20, 20, 20, .5);
                animation: animate 10s linear infinite;
            }
        }

        a:hover{
            .liquid{
                top: -180px;
            }
        }
    }
}

.homeTides.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px) {
    .homeTides {
        padding: 50px 20px 100px;

        .homeTidesTitle {
            h1 {
                font-size: 25px !important;
            }
        }

        .homeTidesContent {
            p {
                font-size: 16px;
            }
            a{
                span{
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .homeTides {
        .waves2{
            bottom: 0px;
        }
    }
}

@media screen and (max-width: 425px) {
    .homeTides {
        padding-bottom: 50px;
        .homeTidesTitle {
            h1 {
                font-size: 22px !important;
            }
        }
        .waves2{
            bottom: -120px;
        }
        .homeTidesContent {
            p{
                font-size: 14px;
                margin-bottom: 25px;
            }
            a{
                span{
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeCareer {
        padding: 0px 20px 100px;
        background-size: 2000px 450px;
        background-position: 45% 40%;

        .careerContent {
            .text {
                padding-top: 40px;

                p {
                    font-size: 14px;
                }

                .careerBtn {
                    transform: translateY(35px);
                }
            }

            .arrows1 {
                transform: translateY(200px);
                left: -80px;
            }

            .arrows2 {
                transform: translateY(-100px);
            }
        }
    }
}