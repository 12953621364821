.mediaButtons{
    z-index: 9999;
    position: fixed;
    bottom: 50px;
    right: 10px;
    opacity: 0%;

    display: grid;
    
    align-items: center;
    justify-content: center;
    gap: 2px;
    transition: .3s ease;

    .link{
        justify-self: center;
        align-self: center;

        background-color: var(--cor-tertiary);
        border-radius: 10px;
        transition: .5s;

        a{
            padding: 8px;
            display: grid;
            width: fit-content !important;
            height: fit-content;
        }
    }
    .link:hover{
        background-color: var(--cor-secondary);
    }
}
.mediaButtons.visible{
    animation: slideToRight 2s 1s ease forwards;
}

@media screen and (max-width: 1440px){
    .mediaButtons{

        .link a img{
            width: 20px;
        }
    }
}