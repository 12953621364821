.newsletter-bg {
    background-image: linear-gradient(45deg, rgba(0, 30, 17, 0.9098039216), rgba(0, 30, 17, 0.8196078431), rgba(0, 32, 18, 0.1333333333)), url("../../img/vessels/vesselsTwo/embarcacao_palangana_vii.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 300px 150px 200px;
    opacity: 0%;

    .newsletter {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 5%;

        h1 {
            color: #fff;
            font-weight: 700;
            max-width: 530px;
            opacity: 0%;
        }

        h1.visible {
            animation: halfToRight 1s .2s ease forwards;
        }

        .line {
            height: 2px;
            width: 140px;
            border-radius: 5px;
            background-color: var(--cor-tertiary);
            opacity: 0%;
        }

        .line.visible {
            animation: slideToRight 1s .4s ease forwards;
        }

        form {
            opacity: 0%;
            padding-top: 15px;
        }

        form.visible {
            animation: halfToLeft 1s .2s ease forwards;
        }

        .form-control {
            border: solid 2px var(--cor-secondary);
            width: 350px;
            background-color: #ffffff94;
        }

        button {
            background-color: var(--cor-tertiary);
            color: white;
            font-weight: 700;
            width: 350px;
            border: 1px solid rgba(255, 255, 255, 0);
        }

        .form-label {
            color: white;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 2px;
        }
    }
}

.newsletter-bg.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px) {
    .newsletter-bg {
        padding: 200px 50px 150px;

        .newsletter {
            h1 {
                max-width: 440px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .newsletter-bg {
        .newsletter {
            a {
                img {
                    width: 200px;
                }

                p {
                    margin: 25px 0px 0px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .newsletter-bg {
        padding: 150px 50px;
        background-size: 1500px;
    }
}

@media screen and (max-width: 600px) {
    .newsletter-bg {
        padding: 150px 50px;
        background-size: 2000px;

        .newsletter {
            display: grid;

            h1 {
                width: fit-content;
                max-width: 100%;
            }

            .line {
                height: 2px;
                width: 100px;
                justify-self: left;
            }

            a {
                justify-self: left;

                img {
                    width: 180px;
                }

                p {
                    font-size: 14px;
                    margin: 22px 0px 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .newsletter-bg {
        padding: 150px 20px;

        .newsletter {
            padding: 0px;
        }
    }
}