.homeHandling {
    display: grid;
    justify-content: center;
    text-align: center;
    padding: 150px 150px;
    background: linear-gradient(0deg, rgba(7, 63, 39, 0.935), rgba(0, 26, 14, 0.73), rgba(0, 21, 12, 0.812), rgba(0, 40, 23, 0.96)) 0px 0px / 1000% no-repeat, url(../../img/home/handling/manobras.webp) 0px 0px / cover no-repeat;


    .handlingTitle {
        display: flex;
        justify-content: center;

        button {
            background-color: rgba(255, 255, 255, 0) !important;
            border: none;
            width: fit-content;
        }

        h1 {
            color: white;
            font-weight: 600;
        }
    }

    .handlingTable {
        padding-top: 50px;
        overflow-x: scroll;

        table {
            --cui-table-bg: rgba(245, 245, 245, 0);
            backdrop-filter: blur(5px);

            thead {
                tr {
                    border: none;
                }

                th {
                    padding: 1rem 1.5rem !important;
                    color: white;
                }
            }

            tr {
                border-color: #0f6640bd;

                th {
                    padding: 1rem 1.5rem;
                    white-space: nowrap;
                }

                td {
                    background-color: rgba(255, 255, 255, 0);
                    white-space: nowrap;
                    color: white;
                }
            }
        }
    }

    .handlingTable::-webkit-scrollbar {
        height: 7px;
    }
}

@media screen and (max-width: 1440px) {
    .homeHandling {
        padding: 100px 50px;
    }
}

@media screen and (max-width: 600px) {
    .homeHandling {
        padding: 50px 20px;

        .handlingTable {
            tbody {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}