.servicesFour {
    background-image: linear-gradient(45deg, rgba(0, 30, 17, 0.801), rgba(0, 30, 17, 0.924), rgba(0, 32, 18, 0.678)), url('../../img/about/img-6.webp');
    background-size: cover;
    padding: 100px 50px 50px;

    .servicesFourTitle {
        display: grid;
        justify-content: center;

        h2 {
            color: var(--cor-white);
            font-size: 30px !important;
            font-weight: 700;
            text-align: center;
            z-index: 2;
        }

        h2.visible {
            animation: slideToRight 2s ease forwards;
        }

        img {
            position: relative;
            top: -20px;
            z-index: 1;
            opacity: 100%;
        }
    }

    .service {
        padding: 2rem 3rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        img {
            transition: .5s ease;
            width: 400px;
            cursor: pointer;
            border-radius: 10px;
        }

        img:hover {
            scale: 1.08;
        }

        img.visible {
            animation: slideToLeft 2s .4s ease forwards;
        }

        .servicesFourText {
            h1 {
                color: var(--cor-tertiary);
                font-size: 30px !important;
                font-weight: 700;
                padding-left: 150px;
                opacity: 0%;
            }

            h1.visible {
                animation: slideToRight 2s ease forwards;
            }



            span {
                color: var(--cor-secondary);
            }

            p {
                padding-top: 10px;
                width: 750px;
                line-height: 25px;
                font-size: 16px;
                padding-left: 150px;
                opacity: 0%;
                color: rgb(238, 238, 238);
            }

            p.visible {
                animation: slideToRight 2s .2s ease forwards;
            }

        }
    }
}

@media screen and (max-width: 1600px) {
    .servicesFour {

        .servicesFourText {
            h1 {
                padding-left: 100px;
            }

            p {
                width: 650px;
                font-size: 16px !important;
                padding-left: 100px;
            }

            img {
                margin: 0px;
                width: 300px;
            }
        }

    }
}

@media screen and (max-width: 1400px) {
    .servicesFour {

        .servicesFourText {
            h1 {
                padding-left: 50px;
            }

            p {
                padding-left: 50px;
                width: 100%;
                max-width: 650px;
                padding-right: 0px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .servicesFour {
        .service {
            padding: 20px 0px;
            gap: 50px;

            .servicesFourText {

                h1,
                p {
                    padding: 0px;
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .servicesFour {
        .service {
            display: grid;
            gap: 20px;

            .servicesFourText {
                h1 {
                    font-size: 22px !important;
                }
            }

            img {
                grid-row-start: 2;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .servicesFour {
        padding: 80px 20px 50px;

        .service {
            img {
                width: 100%;
            }

            .servicesFourText {
                p {
                    font-size: 14px !important;
                }
            }
        }
    }
}