.contactOne {
    padding: 200px 150px 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    background-image: url("../../img/about/barco.webp");
    background-size: 900px;
    background-position: 110% 100%;
    background-repeat: no-repeat;
    opacity: 0%;
    gap: 6%;

    .contactOneText {
        margin-top: 3rem;

        h3 {
            font-size: 22px !important;
            width: 700px;
            margin: 0px;
            opacity: 0%;
            color: var(--cor-secondary);
            width: 400px;
        }

        h3.visible {
            animation: fadeIn 1s .3s ease forwards;
        }

        p {
            font-size: 18px !important;
            width: 700px;
            margin: 0px;
            opacity: 0%;
            color: var(--cor-secondary);
        }

        p.visible {
            animation: fadeIn 1s .3s ease forwards;
        }

        .address {
            padding-top: 20px;
            color: var(--cor-primary);
            font-weight: 400;
            opacity: 0%;
        }

        .address.visible {
            animation: fadeIn 1s .4s ease forwards;
        }

        .email {
            padding-top: 20px !important;

            img {
                width: 25px;
                height: 25px;
            }
        }

        .email.visible {
            animation: fadeIn 1s .5s ease forwards;
        }

        .phone {
            img {
                width: 25px;
                height: 25px;
            }
        }

        .phone.visible {
            animation: fadeIn 1s .6s ease forwards;
        }

        .phone2 {
            img {
                width: 25px;
            }
        }

        .phone2.visible {
            animation: fadeIn 1s .7s ease forwards;
        }

        .email,
        .phone,
        .phone2 {
            padding-top: 10px;
            align-items: center;
            gap: 10px;
            display: flex;
            opacity: 0%;

            p {
                color: var(--cor-primary);
                margin: 0px;
                font-weight: 400;
                opacity: 100%;
            }
        }
    }

    form {
        padding: 50px 20px;
        border-radius: 20px;
        width: 500px;
        justify-self: right;
        opacity: 0%;

        .form-control {
            border-radius: 15px;
        }

        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            width: 350px;
            margin: 0px;
            padding-bottom: 20px;
            opacity: 0%;

        }

        h1.visible {
            animation: fadeIn 1s .2s ease forwards;
        }

        label {
            margin: 0px !important;
            color: var(--cor-primary);
        }

        input {
            line-height: 1.2;
            border: 2px solid var(--cor-tertiary);
            margin-bottom: 10px;
        }

        textarea {
            border: 2px solid var(--cor-primary);
        }


        button {
            position: relative;
            padding: 10px 120px;
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            width: 310px;
            overflow: hidden;
            border-radius: 40px;
            align-self: center;
            justify-self: center;
            border: 1px solid rgba(255, 255, 255, 0);
            background-color: rgba(255, 255, 255, 0);

            span {
                position: relative;
                color: #fff;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: normal;
                z-index: 1;
            }

            .liquid {
                position: absolute;
                top: -130px;
                left: 0;
                width: 310px;
                height: 300px;
                background: var(--cor-primary);
                box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
                transition: .5s;
            }

            .liquid::after,
            .liquid::before {
                content: '';
                width: 200%;
                height: 200%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -75%);
                background: #004629;
            }

            .liquid::before {
                border-radius: 45%;
                background: #004629;
                animation: animate 5s linear infinite;
            }

            .liquid::after {
                border-radius: 40%;
                background: rgba(20, 20, 20, .5);
                animation: animate 10s linear infinite;
            }
        }

        a:hover {
            .liquid {
                top: -180px;
            }
        }

        @keyframes animate {
            0% {
                transform: translate(-50%, -75%) rotate(0deg);
            }

            100% {
                transform: translate(-50%, -75%) rotate(360deg);
            }
        }

        button:hover {
            background-color: var(--cor-tertiary);
        }
    }

    form.visible {
        animation: fadeIn 1s .5s ease forwards;
    }
}

.contactOne.visible {
    animation: fadeIn 2s ease forwards;
}

@media screen and (max-width: 1600px) {
    .contactOne {
        padding: 200px 50px 20px;
        background-size: 600px;

        .contactOneText {

            p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .contactOne {
        padding: 150px 50px 20px;

        .contactOneText {
            p {
                width: 100%;
            }

            .address,
            .email,
            .phone,
            .whats {
                font-size: 15px;

                p {
                    font-size: 15px !important;
                }
            }
        }

        form {
            width: 450px;

            label,
            input {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .contactOne {
        background-size: 400px;

        form {
            max-width: 450px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .contactOne {
        grid-template-columns: 100%;
        gap: 0px;

        .contactOneText {
            padding: 0px;

            p {
                max-width: 600px;
            }
        }

        .contacts {
            display: flex;
            gap: 5%;

            .phone,
            .whats {
                padding-top: 20px;
            }
        }

        form {
            margin-top: 50px;
            padding: 30px 20px;
            max-width: 450px;
            width: 100%;
            justify-self: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .contactOne {
        padding-top: 130px;
        background-size: 200px;
        margin-bottom: 3rem;

        .contacts {
            gap: 0px;
            display: grid;

            .phone,
            .whats {
                padding-top: 5px;
            }
        }

        form {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 500px) {
    .contactOne {
        padding: 130px 20px 20px;

        form {
            h1 {
                width: 100%;
            }

            button {
                width: 250px !important;
                height: 50px;
                padding: 0px;

                .liquid {
                    width: 250px;
                }
            }
        }

        .contactOneText {
            h3 {
                width: 100%;
                font-size: 18px !important;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .contactOne {
        .contactOneText {
            h1 {
                width: 100%;
            }

            p {
                font-size: 14px;
            }
        }

        .contacts {
            img {
                width: 20px !important;
                height: 20px !important;
            }

            .address {
                font-size: 14px !important;
            }

            .email,
            .phone,
            .whats {
                p {
                    font-size: 14px !important;
                }
            }
        }

        form {
            padding: 30px 10px;

            input,
            textarea {
                border: 1px solid var(--cor-secondary);
            }
        }
    }
}